:root {
    --bright-teal: rgb(17.1% 63% 63%); /* lch(60% 34 197) */
    --dark-teal: rgb(0.87% 32.3% 32.4%); /* lch(31% 23 197) */
    --light-green: rgb(58.9% 100% 100%); /* lch(94% 33.7 197) */
    --plum: rgb(15.2% 7.93% 18.4%); /* lch(10% 20 315) dark plum */
    --slate: rgb(5.38% 8.67% 13.57%);
    --red: rgb(55.23% 1.67% 21.63%); /* lch(30 54 13) */
    --blue: rgb(3.47% 40.55% 51.6%); /* lch(40 29 233) */
    --indigo: rgb(36.72% 52.13% 96.99%); /* lch 57 64 282 */
    font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--slate);
  color: var(--light-green);
}

#page {
    display: flex;
}

/* for some reason 1/1 does not work */
@media (max-aspect-ratio: 2/2) {
    #page {
        flex-direction: column;
        height: 100dvh;
    }

    canvas {
        height: 100%;
        width: 100vw;
        border-width: 0;
        border-bottom-width: 1px;
    }

  #tools {
      width: 100%;
      bottom-margin: 10em;
  }
}

@media (min-aspect-ratio: 2/2) {
    canvas {
        height: 100dvh;
        width: 100%;
        border-width: 0;
        border-right-width: 1px;
    }

    #tools {
        width: min(25em, 25vw);
    }
}

canvas {
    border-style: solid;
    border-color: var(--blue);
}

#tools {
    padding: 8px;
    box-sizing: border-box;
}

button,
a.button {
  color: var(--bright-teal);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: var(--bright-teal);
  font-size: inherit;
}

a.button {
  padding: 0 0.2em;
}

input {
  font-size: inherit;
}

textarea, select,
input[type="text"] {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: var(--indigo);
  color: var(--light-green);
}

textarea:focus,
input[type="text"].primary,
input[type="text"]:focus {
  background-color: var(--dark-teal);
}

button,
input,
select,
textarea {
  font-family: inherit;
}

textarea {
  width: 100%;
  background-color: transparent;
}

hr {
    border-width: 0;
    border-top: 1px solid var(--blue);
    height: 1px;
    background-color: transparent;
}
