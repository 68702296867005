:root {
  --bright-teal: #2ca1a1;
  --dark-teal: #025253;
  --light-green: #96ffff;
  --plum: #27142f;
  --slate: #0e1623;
  --red: #8d0437;
  --blue: #096784;
  --indigo: #5e85f7;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--slate);
  color: var(--light-green);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#page {
  display: flex;
}

@media (aspect-ratio <= 2 / 2) {
  #page {
    flex-direction: column;
    height: 100dvh;
  }

  canvas {
    border-width: 0 0 1px;
    width: 100vw;
    height: 100%;
  }

  #tools {
    bottom-margin: 10em;
    width: 100%;
  }
}

@media (aspect-ratio >= 2 / 2) {
  canvas {
    border-width: 0 1px 0 0;
    width: 100%;
    height: 100dvh;
  }

  #tools {
    width: min(25em, 25vw);
  }
}

canvas {
  border-style: solid;
  border-color: var(--blue);
}

#tools {
  box-sizing: border-box;
  padding: 8px;
}

button, a.button {
  color: var(--bright-teal);
  border-style: solid;
  border-width: 1px;
  border-color: var(--bright-teal);
  font-size: inherit;
  background-color: #0000;
}

a.button {
  padding: 0 .2em;
}

input {
  font-size: inherit;
}

textarea, select, input[type="text"] {
  border-style: solid;
  border-width: 1px;
  border-color: var(--indigo);
  color: var(--light-green);
  background-color: #0000;
}

textarea:focus, input[type="text"].primary, input[type="text"]:focus {
  background-color: var(--dark-teal);
}

button, input, select, textarea {
  font-family: inherit;
}

textarea {
  background-color: #0000;
  width: 100%;
}

hr {
  border-width: 0;
  border-top: 1px solid var(--blue);
  background-color: #0000;
  height: 1px;
}

/*# sourceMappingURL=index.50d5cc21.css.map */
